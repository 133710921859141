import React, { Component } from 'react';

import { dateFomatter } from '../../../utils/date-formatter';
import { currencyFormat } from '../../../utils/currency-formatter';
import QRCode from 'react-qr-code';
import './styles.css';

class TicketPrint extends Component{
    
    render(){
        return <div className="ticket-details-content">
            <div className="ticket">
                <div className="left">
                    <div className="image">
                        <img 
                            src={this.props.ticket && this.props.ticket.event ? this.props.ticket.event.image : null} 
                            alt={ this.props.ticket && this.props.ticket.status === 'NOVO' && this.props.ticket.event ? this.props.ticket.event.name : '----'} />
                        <p className="admit-one"> <span>TÁ NA MÃO</span> <span>TÁ NA MÃO</span> <span>TÁ NA MÃO</span> </p>
                    </div>
                    <div className="ticket-info">
                        <p className="date">
                            <span>{ this.props.ticket && this.props.ticket.status === 'NOVO' && this.props.ticket.event ? dateFomatter(this.props.ticket.event.start_at, "dd").toUpperCase() : '----' }</span>
                            <span className="june-29">{ this.props.ticket && this.props.ticket.status === 'NOVO' && this.props.ticket.event ? dateFomatter(this.props.ticket.event.start_at, "MMMM").toUpperCase() : '----' }</span>
                            <span>{ this.props.ticket && this.props.ticket.status === 'NOVO' && this.props.ticket.event ? dateFomatter(this.props.ticket.event.start_at, "yyyy") : '----' }</span>
                        </p>
                        <div className="show-name">
                            <h1>{ this.props.ticket && this.props.ticket.status === 'NOVO' && this.props.ticket.event ? this.props.ticket.event.name : '----' }</h1>
                            <h2>{ this.props.ticket && this.props.ticket.status === 'NOVO' && this.props.ticket.event ? this.props.ticket.event.place_of_establishment : '----' }</h2>
                        </div>
                        <div className="time">
                            { this.props.ticket && this.props.ticket.status === 'NOVO' && this.props.ticket.ticketType ? (this.props.ticket.ticketType.name + ' - ' + this.props.ticket.ticketType.type) : '----' }
                        </div>
                        { this.props.ticket.value === 0 ?
                                <div className="time" style={{color: 'red'}}>
                                    { this.props.ticket && this.props.ticket.status === 'NOVO' && this.props.ticket.lote ? this.props.ticket.lote.promotion_name : '----' }
                                </div>
                            : <div>
                                <p className='ticket-value-unit'>
                                    Preço Unitário: { this.props.ticket && this.props.ticket.status === 'NOVO' ? currencyFormat(this.props.ticket.value_without_tax) : '----' }
                                </p> 
                                <p className='ticket-event-tax'>
                                    Taxa de conveniência { this.props.ticket && this.props.ticket.status === 'NOVO' ? currencyFormat(this.props.ticket.value_without_tax * (this.props.ticket.online_tax / 100)) : '----' }
                                </p>
                                <p className='ticket-value-total'>
                                    Total: { this.props.ticket && this.props.ticket.status === 'NOVO' ? currencyFormat(this.props.ticket.value) : '----' }
                                </p>
                        </div>}
                        
                        <div className="time">
                            { this.props.ticket && this.props.ticket.status === 'NOVO' && this.props.ticket.event ? dateFomatter(this.props.ticket.event.start_at, "'Apartir das' HH:mm 'horas' ") : '----' }
                        </div>
                        <p className="location"><span>Tá na Mão Ingressos On-line</span>
                            <span className="separator"><i className="far fa-smile"></i></span><span>Impressão individual e pessoal.</span>
                        </p>
                    </div>
                </div>
                <div className="right">
                    <p className="admit-one">
                        <span>TÁ NA MÃO</span>
                        <span>TÁ NA MÃO</span>
                        <span>TÁ NA MÃO</span>
                    </p>
                    <div className="right-info-container">
                        <div className="show-name">
                            <h1>{ this.props.ticket && this.props.ticket.status === 'NOVO' && this.props.ticket.event ? this.props.ticket.event.name : '----'  }</h1>
                        </div>
                        <div className="time">
                            <p>{ this.props.ticket && this.props.ticket.status === 'NOVO' && this.props.ticket.event ? dateFomatter(this.props.ticket.event.start_at, "'Apartir das' HH:mm 'horas' ") : '----' }</p>
                            <p>{ this.props.ticket && this.props.ticket.status === 'NOVO' && this.props.ticket.event ? this.props.ticket.event.place_of_establishment : '----' }</p>
                        </div>
                        <div className="barcode">
                            {
                                this.props.ticket.status === 'NOVO' ?
                                <QRCode 
                                    size={128}
                                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                                    value={ this.props.ticket.status === 'NOVO' ? this.props.controlNumber : '000' }
                                    viewBox={`0 0 256 256`}
                                    />
                                : null
                            }
                        </div>
                        <p className="ticket-number">
                            { this.props.ticket && this.props.ticket.status === 'NOVO' ? this.props.ticket.control_number : '----' }
                        </p>
                    </div>
                </div>
            </div>                       
        </div>
    };

}
export default TicketPrint;

