import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "react-credit-cards/es/styles-compiled.css";
import MaskedInput from "react-text-mask";
import Cards from "react-credit-cards";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import api from "./../../../services/api";

import './styles.css';

import { useScript } from "../../../utils/use-script";
import { currencyFormat } from "../../../utils/currency-formatter";

const cardExpirationDateMask = [
  /[0-9]/,
  /[0-9]/,
  "/",
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
  /[0-9]/,
];
const PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;

export default function Pagamento(props) {
  const { PagSeguro } = useScript(
    "https://assets.pagseguro.com.br/checkout-sdk-js/rc/dist/browser/pagseguro.min.js",
    "PagSeguro"
  );
  const [focuss, setFocuss] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardHolderName, setCardHolderName] = useState("");
  const [cardExpirationDate, setCardExpirationDate] = useState("");
  const [cardCVV, setCardCVV] = useState("");
  const token = localStorage.getItem("token");
  const valorTotalSalvo = localStorage.getItem("valorTotal");
  const history = useHistory();
  const [processingOrder, setProcessingOrder] = useState(false);
  const [loadTaxes, setLoadTaxes] = useState(false);
  const [fees, setFees] = useState([]);
  const [feeSelected, setFeeSelected] = useState();
  const [valorTotal, setValorTotal] = useState();
  const [cardBinPesquisado, setCardBinPesquisado] = useState();
  const [installments, setInstallments] = useState(1);
  const [methodPayments, setMethodPayments] = useState([]);
  const [methodPaymentSelected, setMethodPaymentSelected] = useState(null);
  const [carregou, setCarregou] = useState(false);
  const [carregouMetodosPagamento, setCarregouMetodosPagamento] = useState(false);

  useEffect(() => {
    const loadUserAuthenticated = async () => {
      api.get(`authenticate`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    };
    loadUserAuthenticated();
  }, [token]);

  useEffect(() => {
    const loadMethodPayments = async () => {
      const response = await api.get(`/site/events/methods_payments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let methods = response.data;
      if (props.disablePIX) {
        methods = methods.filter(m => `${m}`.toUpperCase() !== 'PIX')
      }
      setMethodPayments(methods);
      
      if (methods.length === 1) {
        setMethodPaymentSelected(methods[0]);
      }
    };
    if (!carregouMetodosPagamento) {
      loadMethodPayments();
      setCarregouMetodosPagamento(true);
    }
  }, [token, methodPayments, props, carregouMetodosPagamento, methodPaymentSelected]);

  useEffect(() => {
    const carregaParcelaEValorTotalPadrao = () => {
      const total = Number(props.valorTotal != null && props.valorTotal !== 0 ? props.valorTotal : valorTotalSalvo);
      const fee = {
        installments: 1,
        installment_value: total * 100,
        interest_free: true,
      };
      setFees([fee]);
      setValorTotal(currencyFormat(total));
    };

    if (!carregou) {
      setCarregou(true);
      carregaParcelaEValorTotalPadrao();
    }
  }, [props, carregou, valorTotalSalvo]);

  const carregaParcelaEValorTotalPadraoGlobal = () => {
    const total = Number(props.valorTotal != null ? props.valorTotal : valorTotalSalvo);
    const fee = {
      installments: 1,
      installment_value: total * 100,
      interest_free: true,
    };
    setFees([fee]);
    setValorTotal(currencyFormat(total));
  };

  const handleInputFocus = (e) => {
    setFocuss(e.target.name);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setProcessingOrder(true);
      var card = PagSeguro.encryptCard({
        publicKey: PUBLIC_KEY,
        holder: cardHolderName,
        number: cardNumber,
        expMonth: cardExpirationDate.split("/")[0],
        expYear: cardExpirationDate.split("/")[1],
        securityCode: cardCVV,
      });

      var encrypted = card.encryptedCard;
      let itens = [];
      if (props.order) {
        for (const item of props.order) {
          const indexOf = itens.findIndex((i) => i.ticket_type_id === item.ticketType.id && i.lote_id === item.ticketType.lote.id);
          if (indexOf < 0) {
            itens.push({
              ticket_type_id: item.ticketType.id,
              lote_id: item.ticketType.lote.id,
              quantity: 1,
            });
          } else {
            itens[indexOf].quantity += 1;
          }
        }
        itens = props.order.map((o) => {
          return {
            ticket_type_id: o.ticketType.id,
            lote_id: o.ticketType.lote.id,
          };
        });
      }

      try {
        let buyer_interest = null;
        if (feeSelected != null && feeSelected.interest_free === false) {
          const { amount } = feeSelected;
          const { fees } = amount;
          const { buyer } = fees;
          buyer_interest = buyer.interest.total / 100;
        }
        const body = {
          payment_method: {
            type: methodPaymentSelected,
            card_token: encrypted,
            installments,
            buyer_interest
          },
          itens,
          session_id: document.getElementById('tanamao_session_id').value,
        };
        const configRequest = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        let response;
        if (props.orderId) {
          response = await api.patch(
            `/clients/orders/${props.orderId}`,
            body,
            configRequest
          );
        } else {
          response = await api.post("/clients/orders", body, configRequest);
        }

        if (response.status === 200 || response.status === 201) {
          if (props.onComplete) {
            props.onComplete();
          }
          if (props.orderId) {
            props.onClose();
          } else {
            alert("Pedido em processamento.");
            history.push(`/cliente/pedidos/${response.data.order_id}`);
          }
        } else {
          alert("Houve um erro processar o pedido.");
        }
      } catch (err) {
        console.log();
        if (err.response && err.response.status === 400) {
          alert(err.response.data.error);
        } else {
          alert("Houve um erro processar o pedido.");
        }
      }
    } catch (err) {
      console.log(err);
      alert(
        "Houve um erro ao efetuar a transação. Verifique os dados do cartão"
      );
    } finally {
      setProcessingOrder(false);
    }
  };

  const calcularValorTotal = (installmentsTmp) => {
    const feesTmp = fees.filter(e => e.installments === Number(installmentsTmp));
    if (feesTmp.length > 0) {
      setFeeSelected(feesTmp[0]);
      const { installments, installment_value } = feesTmp[0];
      const total = (Number(installments) * Number(installment_value)).toFixed(2);
      setValorTotal(currencyFormat(Number(total / 100)));
    } else {
      setValorTotal(currencyFormat(Number(props.valorTotal)));
    }
  };

  const carregarParcelas = async (cardNumberTmp) => {
    try {
      if (cardNumberTmp.length < 6 || cardBinPesquisado === cardNumberTmp.substring(0, 6)) {
        return;
      }
      const cardBin = cardNumberTmp.substring(0, 6);
      setCardBinPesquisado(cardBin);
      setLoadTaxes(true);
      const value = Number(Number(`${props.valorTotal}`).toFixed(2));
      const response = await api.get(`events/${props.eventId}/orders/taxes?value=${value}&credit_card_bin=${cardBin}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.status === 200) {
        if (response.data.length > 0) {
          setFees(response.data);
        } else {
          carregaParcelaEValorTotalPadraoGlobal();
        }
      }
    } catch (err) {
      console.log(err);
      alert('Erro ao carregar as parcelas.');
    } finally {
      setLoadTaxes(false);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        keepMounted={false}
        onClose={props.onClose}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title" color="secondary">
          <span style={{ color: "blue" }}>
            { methodPayments.length === 1 ? methodPayments[0] === 'pix' ? 'PIX' : 'Cartão de crédito' : 'Selecione o método de pagamento'}
          </span>
        </DialogTitle>
        <DialogContent>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              marginBottom: '10px',
            }}>
              {
                methodPayments.length > 1 ?
                methodPayments.map((m) => {
                  return (
                    <div key={m}>
                      <input 
                        type="radio" 
                        name="method_payment" 
                        id={m} 
                        value={m} 
                        onChange={(event) => setMethodPaymentSelected(event.target.value)}/>
                      <label for={m}>{ m === 'pix' ? 'PIX' : 'Cartão de crédito' }</label>
                    </div>
                  );
                }) : null
              }
            </div>
            {
              methodPaymentSelected === 'credit_card' ? 
              <form
              onSubmit={(e) => handleSubmit(e)}
              style={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
              <div>
                <input
                  type="text"
                  value={cardNumber}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                    setCardNumber(e.target.value);
                    carregarParcelas(e.target.value);
                  }}
                  id="cardNumber"
                  name="cardNumber"
                  placeholder="Número do Cartão de Crédito"
                  maxLength="24"
                  onFocus={handleInputFocus}
                  required
                  disabled={processingOrder}
                />

                <input
                  type="text"
                  value={cardHolderName}
                  onChange={(e) => setCardHolderName(e.target.value.toUpperCase())}
                  id="cardHolderName"
                  name="cardHolderName"
                  placeholder="Nome presente no Cartão"
                  onFocus={handleInputFocus}
                  required
                  disabled={processingOrder}
                />
              </div>

              <div className="date_and_cvv">
                <MaskedInput
                  mask={cardExpirationDateMask}
                  value={cardExpirationDate}
                  onChange={(e) => {
                    setCardExpirationDate(e.target.value);
                  }}
                  type="text"
                  onFocus={handleInputFocus}
                  id="cardExpirationDate"
                  name="cardExpirationDate"
                  placeholder="00/0000"
                  required
                  disabled={processingOrder}
                />

                <input
                  type="text"
                  value={cardCVV}
                  onChange={(e) => {
                    e.target.value = e.target.value.replace(/\D/g, "");
                    setCardCVV(e.target.value);
                  }}
                  id="cardCVV"
                  name="cvc"
                  placeholder="Cód de Segurança"
                  maxLength="4"
                  onFocus={handleInputFocus}
                  required
                  onBlur={() => setFocuss("")}
                  disabled={processingOrder}
                />
              </div>

              <br />
              <div style={{ margin: "0 0 !important" }}>
                <Cards
                  number={cardNumber.toString()}
                  expiry={cardExpirationDate}
                  focused={focuss}
                  cvc={cardCVV}
                  name={cardHolderName}
                  />
                </div>
              <br />

              <div className="valor-total">
                <div style={{ padding: "0.5em" }}>
                  <div>
                    <span>Parcela(s): </span>
                    <select value={installments} 
                      onChange={(e) => {
                        setInstallments(e.target.value);
                        calcularValorTotal(e.target.value);
                      }} 
                      disabled={ loadTaxes }>
                      {
                        fees.map((element) => {
                          return (
                            <option value={element.installments} key={element.installments}>
                              { element.installments + ' de ' + currencyFormat(element.installment_value / 100) }
                            </option>
                          );
                        })
                      }
                    </select>
                    {
                      loadTaxes ? <span style={{ marginLeft: "5px", fontSize: 12 }}>Carregando...</span> : null
                    }
                    {
                      cardNumber.length === 0 ? <span style={{ marginLeft: "5px", fontSize: 12 }} >Informe o número do cartão para carregar mais parcelas</span> : null
                    }
                  </div>
                  <div>
                    <span>Valor Total: </span>
                    <span
                      style={{ fontSize: 25, fontWeight: "bold", color: "#435a05" }}
                    >
                      { 
                        valorTotal
                      }
                    </span>
                  </div>
                </div>
              </div>

              <br />
              <span>* Confira todos os dados antes de finalizar a compra!</span>
              <br />

              <center>
                <input
                  type="submit"
                  value={processingOrder ? "Finalizando..." : "Finalizar e Pagar"}
                  disabled={
                    processingOrder || !props.valorTotal || props.valorTotal === 0
                  }
                />
              </center>
            </form> : null
            }
            
            {
              methodPaymentSelected === 'pix' ? 
              <div>
                <h3 style={{ textAlign: 'center' }}>O Tá na mão irá gerar o QrCode de PIX</h3>

                <center>
                  <input
                    type="button"
                    onClick={(e) => handleSubmit(e)}
                    value={processingOrder ? "Finalizando..." : "Finalizar"}
                    disabled={
                      processingOrder || !props.valorTotal || props.valorTotal === 0
                    }
                  />
                </center>
              </div>
              : null
            }

            {
              methodPaymentSelected == null || methodPaymentSelected === undefined ? <h3 style={{ textAlign: 'center' }}>Selecione o método de pagamento</h3> : null
            }
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
