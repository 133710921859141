export const currencyFormat = (num) => {
  if (num != null) {
    var formatter = Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumSignificantDigits: 3,
      maximumFractionDigits: 2,
    });
    return formatter.format(Math.floor(Number(num) * 100) / 100);
  } else {
    return '';
  }
};
